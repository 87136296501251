import PageBottom from './page-bottom/PageBottom';
import OtpComponent from './otp-component/OtpComponent';
const EmailOtpVerification = () => {
  return (
    <div className='row justify-content-center align-items-center h-100'>
      <div className='col-sm-3 px-0'>
        <OtpComponent type='email' />
        <PageBottom />
      </div>
    </div>
  );
};

export default EmailOtpVerification;
