

 const SidebarData = [
  {
    title: 'Login',
    path: '/',
    cName: 'nav-text'
  },
  {
    title: 'Sign Up',
    path: '/signup',
    cName: 'nav-text'
  },
  
];

export default SidebarData