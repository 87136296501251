import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
// import { saveInfoAndPush, saveInfoWithKey } from '../LoginSingupInfoManager';
// import { logOut } from '../../api/nav';
import { useDispatch, useSelector } from 'react-redux';
// import { updateAuthData } from '../../redux/actions/authAction';
import { Container, Navbar } from 'react-bootstrap';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { updateSelectedNav } from '../../redux/actions/headerAction';
import Logo from '../whitelabel/Logo';
import './Navbar.scss';
import SidebarData from './SidebarData';

const Header = () => {
  const navigate = useNavigate();
  const { user_id } = useSelector((state) => state.authReducer);
  const { activeLink, websiteURL } = useSelector(
    (state) => state.headerReducer
  );
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  useEffect(() => {
    if (location.pathname && user_id) {
      navigate.push('/');
    }
    // eslint-disable-next-line
  }, []);

  const updateActiveLink = (link) => {
    dispatch(updateSelectedNav(link));
  };

  // const onLogoutClick = () => {
  //   logOut(user_id).then(() => {
  //     saveInfoAndPush(null, '/login', false, navigate);
  //     localStorage.removeItem('panData');
  //     saveInfoWithKey('userData', null, '/login', false);
  //     dispatch(updateAuthData({ user_id: null }));
  //   });
  // };

  const location = useLocation();
  const getTitle = (value) => {
    let name = value.replace(' ', '').toLowerCase();
    return name;
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand='lg'
        variant='light'
        className=' navbar-light bg-white mt-2 mb-4 mx-0'
      >
        <Container fluid>
          <div className='navbar'>
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          </div>
          <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items' onClick={showSidebar}>
              <a
                href={`${websiteURL}`}
                rel='noreferrer'
                className='navbar-brand mb-4 mt-4'
              >
                <Logo />
              </a>
              <Link to='#' className='menu-bars mr-4 mt-4 float-right'>
                <AiIcons.AiOutlineClose className='mr-2' />
              </Link>
              {SidebarData.map((item, index) => {
                return (
                  <>
                    <li
                      key={index}
                      className={`${item.cName} nav-item mr-4 ${
                        activeLink === getTitle(item.title) && 'active'
                      }`}
                      onClick={() => updateActiveLink(getTitle(item.title))}
                    >
                      <Link to={item.path} className='nav-link mt-3' smooth>
                        <span>{item.title}</span>
                        <span className='float-right mr-20'>
                          <IoIosArrowForward />
                        </span>
                      </Link>
                    </li>
                    <hr className='m-0 p-0 ml-3 w-75'></hr>
                  </>
                );
              })}
              <hr className='m-0 p-0 ml-3 w-75'></hr>
            </ul>
          </nav>
          <a href={`${websiteURL}`} rel='noreferrer' className='navbar-brand'>
            <Logo />
          </a>
          <Link to='/'>
            <Button className='br-light '>Login</Button>
          </Link>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
