import { useState } from 'react';
import PageBottom from '../components/page-bottom/PageBottom';
import { Form } from 'react-bootstrap';
import { resetPassword } from '../api/authentication';
import {
  getInfo,
  getInfoWithKey,
  saveInfoAndPush,
  saveInfoWithKey,
} from './LoginSingupInfoManager';
import SuccessModal from './modal/SuccessModal';
import { updateAuthData } from '../redux/actions/authAction';
import { useDispatch } from 'react-redux';
import { decryptData } from '../utility/DecryptHelper';
import { useEffect } from 'react';
import ResetSuccessModal from './ResetSuccessModal';
import HeaderLogo from './logo/HeaderLogo';
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { validationPatterns } from '../constant/ValidationPatterns';
import { ConstantStrings } from '../constant/ConstantStrings';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitBtnState, setBtnState] = useState(true);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [populateMessage, setPopulateMessage] = useState(true);
  const [successfulModal, setSuccessfulModal] = useState({
    show: false,
    data: null,
  });
  const [passwordMatch, setPasswordMatch] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const closeSuccessFulModal = () => {
    setSuccessfulModal({ show: false });
    saveInfoAndPush({}, '/resetsuccess', false, navigate);
  };

  useEffect(() => {
    const flowData = getInfoWithKey('flowData');
    if (flowData && !flowData.data) {
      navigate('/resetsuccess');
    }
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  const submit = () => {
    if (password === confirmPassword) {
      if (!submitBtnState) {
        const data = new FormData();
        const userInfo = getInfo();
        data.append('token', userInfo.data.token);
        data.append('password', password);

        resetPassword(data).then((res) => {
          if (res.data.status.code !== 0) {
            const resData = decryptData(res.data.data);
            saveInfoWithKey('userData', res.data.data, '/resetsuccess', true);
            saveInfoWithKey(
              'flowData',
              res.data.status.message,
              '/resetsuccess',
              false
            );
            dispatch(updateAuthData(resData));
            if (windowDimensions.width > 480) {
              navigate('/resetsuccess');
              setSuccessfulModal({
                show: true,
                message: 'Password updated successfully. please login again',
              });
            } else {
              setResetSuccess(true);
            }
          }
        });
      }
    } else {
      setPasswordMatch('Password does not match');
    }
  };

  const passwordChange = (event) => {
    let password = event.target.value || '',
      passwordErr = '',
      submitBtnState = true;
    if (!password || !validationPatterns.passwordRegex(8,15).test(password)) {
      setPopulateMessage(false);
       passwordErr = ConstantStrings.PREPOPULATE_PASSWORD_STRING;
    } else {
      submitBtnState = false;
      passwordErr = '';
    }
    setPasswordError(passwordErr);
    setBtnState(submitBtnState);
    setPassword(password);
  };
  const confirmPasswordChange = (event) => {
    let confirmPassword = event.target.value || '',
      passwordErr = '',
      submitBtnState = true;
    if (!confirmPassword) {
      passwordErr = ConstantStrings.PREPOPULATE_PASSWORD_STRING;
    } else if (password !== confirmPassword) {
      passwordErr = 'Passwords must match';
    } else {
      submitBtnState = false;
      passwordErr = '';
    }
    setConfirmPasswordError(passwordErr);
    setBtnState(submitBtnState);
    setConfirmPassword(confirmPassword);
  };
  const enterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  };
  return (
    <div className='row justify-content-center align-items-center h-100'>
      <div className='col-sm-3 px-0'>
        <BrowserView>
          <div className=' p-5 colored-box mb-6'>
            <HeaderLogo />
            <div className='h4 text-center heading-custom'>Reset Password </div>
            <div className='row mt-5 d-flex justify-content-between align-items-center'>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Control
                  type='password'
                  placeholder='Enter New Password'
                  onChange={passwordChange}
                  value={password}
                  className={
                    'font-weight-medium ' + (passwordError ? 'is-invalid' : '')
                  }
                  onKeyDown={enterHandler}
                />
                <Form.Control.Feedback type='invalid'>
                  {passwordError}
                </Form.Control.Feedback>
             
              </Form.Group>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Control
                  type='password'
                  placeholder='Confirm New Password'
                  onChange={confirmPasswordChange}
                  value={confirmPassword}
                  className={
                    'font-weight-medium ' +
                    (confirmPasswordError ? 'is-invalid' : '')
                  }
                  onKeyDown={enterHandler}
                />
                <Form.Control.Feedback type='invalid'>
                  {confirmPasswordError}
                </Form.Control.Feedback>
                {populateMessage && (
                  <p
                    style={{
                      color: '#dc3545',
                      fontSize: 'small',
                      marginTop: '10px',
                    }}
                  >
                    {ConstantStrings.PREPOPULATE_PASSWORD_STRING}
                  </p>
                )}
              </Form.Group>
            </div>
            <p>{passwordMatch}</p>
        
            <div className='mt-5 d-flex justify-content-center align-items-center'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={submit}
                disabled={submitBtnState}
              >
                Next
              </button>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className=' p-5 bg-light-grey pb-6'>
            <div className='h4 text-center heading-custom'>Reset Password </div>
            <div className='row mt-5 d-flex justify-content-between align-items-center'>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Label className=' opacity-50  mb-1'>
                  Enter New Password
                </Form.Label>
                <Form.Control
                  type='password'
                  onChange={passwordChange}
                  value={password}
                  className={
                    'font-weight-medium  updated-input bg-light-grey' +
                    (passwordError ? 'is-invalid' : '')
                  }
                  onKeyDown={enterHandler}
                />
                <Form.Control.Feedback type='invalid'>
                  {passwordError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Label className=' opacity-50  mb-1'>
                  Confirm New Password
                </Form.Label>
                <Form.Control
                  type='password'
                  onChange={confirmPasswordChange}
                  value={confirmPassword}
                  className={
                    'font-weight-medium updated-input bg-light-grey' +
                    (confirmPasswordError ? 'is-invalid' : '')
                  }
                  onKeyDown={enterHandler}
                />
                <Form.Control.Feedback type='invalid'>
                  {confirmPasswordError}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <p>{passwordMatch}</p>
            <div className='mt-5 d-flex justify-content-center align-items-center'>
              <button
                type='button'
                className='btn btn-primary w-100'
                onClick={submit}
                disabled={submitBtnState}
              >
                Next
              </button>
            </div>
          </div>
        </MobileView>
        {successfulModal.show ? (
          <SuccessModal
            show={successfulModal.show}
            onSubmit={closeSuccessFulModal}
            successText='Password has been updated successfully. Please login'
            size='sm'
          />
        ) : null}
        {resetSuccess ? (
          <ResetSuccessModal show={resetSuccess} message={resetSuccess} />
        ) : null}
        <PageBottom />
      </div>
    </div>
  );
};

export default ResetPassword;
