import iZtoast from 'izitoast';

const Toast = {
  error: (message, title = 'Error') => {
    return iZtoast.error({
      title: title,
      message: message,
      position: 'topRight',
      timeout: 3000,
    });
  },
  success: (message, title = 'Success') => {
    return iZtoast.success({
      title: title,
      message: message,
      position: 'topRight',
      timeout: 3000,
    });
  },
  destroy: () => {
    iZtoast.destroy()
  }
};

export default Toast;
