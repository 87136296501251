import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordOTP } from '../api/authentication';
import { decryptData } from '../utility/DecryptHelper';
import { emailRegex, mobileRegex } from '../utility/constant';
import { saveInfoAndPush } from './LoginSingupInfoManager';
import HeaderLogo from './logo/HeaderLogo';
import PageBottom from './page-bottom/PageBottom';
import Toast from './toast/Toast';

const EmailOrMobileVerify = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [submitBtnState, setBtnState] = useState(true);
  const [userNameError, setUserNameError] = useState('');

  const submit = () => {
    if (!submitBtnState) {
      const data = new FormData();
      data.append('email', userName);
      forgotPasswordOTP(data).then((res) => {
        if (res.data.status.code === 1) {
          let otpData = decryptData(res.data.data);
          otpData['userId'] = userName;
          saveInfoAndPush(otpData, '/otpverify', false, navigate);
        } else if (res.data.status.code === 2) {
          Toast.error(res.data.status.message);
        }
      });
    }
  };

  const userNameHandler = (event) => {
    let inputValue = event.target.value || '';
    let errorMsg = '',
      submitBtnState = true;
    if (!inputValue) {
      errorMsg = 'Please enter a valid Phone number or email address';
    } else if (isNaN(inputValue) && !emailRegex.test(inputValue)) {
      errorMsg = 'Please enter a valid email address';
    } else if (!isNaN(inputValue) && !mobileRegex.test(inputValue)) {
      errorMsg = 'Please enter a valid Phone number';
    } else {
      submitBtnState = false;
      errorMsg = '';
    }
    if (isNaN(inputValue)) {
      inputValue = inputValue.toLowerCase();
    }
    setUserNameError(errorMsg);
    setBtnState(submitBtnState);
    setUserName(inputValue);
  };

  const goToSingup = () => {
    navigate('/');
  };
  const enterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  };
  return (
    <div className='row justify-content-center align-items-center h-100'>
      <div className='col-sm-3 px-0'>
        <BrowserView>
          <div className=' p-5 colored-box mb-6'>
            <HeaderLogo />
            <div className='h4 text-center heading-custom'>Forgot Password</div>
            <div className='row mt-5 d-flex justify-content-between align-items-center'>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Control
                  placeholder='Enter email or mobile no.'
                  onChange={userNameHandler}
                  value={userName}
                  className={
                    'font-weight-medium ' + (userNameError ? 'is-invalid' : '')
                  }
                  onKeyDown={enterHandler}
                />
                <Form.Control.Feedback type='invalid'>
                  {userNameError}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={goToSingup}
              >
                Back
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={submit}
                disabled={submitBtnState}
              >
                Next
              </button>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className=' p-5 bg-light-grey pb-6'>
            <div className='h4 text-center heading-custom'>Forgot Password</div>
            <div className='row mt-5 d-flex justify-content-between align-items-center'>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Label className=' opacity-50  mb-1'>
                  Enter your email or mobile number
                </Form.Label>
                <Form.Control
                  placeholder=''
                  onChange={userNameHandler}
                  value={userName}
                  className={
                    'font-weight-medium updated-input bg-light-grey' +
                    (userNameError ? 'is-invalid' : '')
                  }
                  onKeyDown={enterHandler}
                />
                <Form.Control.Feedback type='invalid'>
                  {userNameError}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              <button
                type='button'
                className='btn btn-primary w-100'
                onClick={submit}
                disabled={submitBtnState}
              >
                Next
              </button>
            </div>
          </div>
        </MobileView>
        <PageBottom />
      </div>
    </div>
  );
};

export default EmailOrMobileVerify;
