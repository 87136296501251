import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignIn from '../components/Signin';
import Signup from '../components/Signup';
import EmailOrMobileVerify from '../components/EmailOrMobileVerify';
import OtpVerify from '../components/OtpVerify';
import ResetPassword from '../components/ResetPassword';
import ResetSuccess from '../components/ResetSuccess';
import MobileOtpVerification from '../components/MobileOtpVerification';
import EmailOtpVerification from '../components/EmailOtpVerification';
import Password from '../components/Password';
import Login from '../components/Login';

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<SignIn />} />
      <Route path="/emailormobileverify" element={<EmailOrMobileVerify />} />
      <Route path="/otpverify" element={<OtpVerify />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/resetsuccess" element={<ResetSuccess />} />
      <Route path="/mobile/otp/verify" element={<MobileOtpVerification />} />
      <Route path="/email/otp/verify" element={<EmailOtpVerification /> }/>
      <Route path="/signin/password" element={<Password />} />
      <Route path="/signin/register" element={<Signup />} />
    </Routes>
  );
};
export default Routers;
