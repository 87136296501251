import { TENANT_NAME_MAPPING } from './constant';

export const formatInput = (e) => {
  let symbolsArr = ['e', 'E', '+', '-'];
  const value = e.target.value;
  if (e.keyCode === 40 && value <= 0) {
    return e.preventDefault();
  }
  return symbolsArr.includes(e.key) && e.preventDefault();
};

export const fcWidgetConfig = {
  cssNames: {
    widget: 'custom_fc_frame',
    expanded: 'custom_fc_expanded',
  },
  headerProperty: {
    hideChatButton: true,
  },
};

export const regexString = (string) => {
  let fundString = string
    .split('-')
    .join(' ')
    .replace(/\s{2,}/g, ' ');
  let finalString = fundString.replace(/[' ']/g, '-');
  let and = finalString.match(/-[&]-/g);
  let numberArray = finalString.match(/-[\d+]/g);
  let params = finalString.match(/-[(]/g);
  let charArray = [and, numberArray, params];
  let characterArray = Array.prototype.concat.apply([], charArray);
  let filtered = characterArray.filter(Boolean);
  if (filtered.length > 0) {
    filtered.map((character) => {
      let char = character.split('-').join('');
      return (finalString = finalString.split(character).join(char));
    });
  }
  return finalString;
};

export const getTenantName = () => {
  const inputString = window.location.hostname;
  const inputStringArray = inputString?.split('.');
  const regex = /-?(auth|uat|corporates|fd)\d?|sandbox/g;
  const result = inputStringArray[0]?.replace(regex, '');
  if (result === 'localhost') return 'elevo';
  else if (Object.keys(TENANT_NAME_MAPPING).includes(result))
    return TENANT_NAME_MAPPING[result];
  return result?.length > 0 ? result : inputStringArray[1];
};

export const getMFAppUrl = (code, mfURL, redirect_to = '') => {
  const passedMfAppURL =
    process.env.NODE_ENV === 'production'
      ? mfURL
      : process.env.REACT_APP_MF_URL;
  const queryParams = {
    code: code,
  };
  let queryString = Object.entries(queryParams)
    .filter(([, value]) => Boolean(value))
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');

  // Check if the redirect_to path already contains query parameters

  if (redirect_to && redirect_to.includes('?')) {
    queryString = `&${queryString}`;
  } else {
    queryString = `?${queryString}`;
  }
  return `${passedMfAppURL}${redirect_to}${queryString}`;
};
