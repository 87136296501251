import PropTypes from 'prop-types';
import { useState } from 'react';
import OTPInput from '../OTPInputComponent';
// import { decryptData } from '../../utility/DecryptHelper';
// import { getInfo, saveInfo, saveInfoAndPush } from '../LoginSingupInfoManager';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resendOTP, verifyOTP } from '../../api/signup';
import {
  setEmailVerified,
  setMobileVerified,
} from '../../redux/reducers/signup-reducer';
import OTPTimer from '../Timer';
import HeaderLogo from '../logo/HeaderLogo';
import Toast from '../toast/Toast';

const OtpComponent = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email, mobile } = useSelector((state) => state.signUpReducer.user);
  // const { emailVerified, mobileVerified } = useSelector(
  //   (state) => state.signUpReducer
  // );
  const otpId =
    useSelector((state) => state.signUpReducer.otpId) ||
    localStorage.getItem('otpId');
  const [otp, setOTP] = useState('');
  const [submitBtnState, setBtnState] = useState(false);
  const [apiError, setApiError] = useState('');
  const [showTimer, setShowTimer] = useState(true);
   
  const otpChange = (event) => {
    let otp = event || '',
      submitBtnState = false;
    const pattern = /^([0-9]{0,6})?$/;

    if (!otp) {
      submitBtnState = false;
    } else if (!pattern.test(otp)) {
      submitBtnState = true;
    } else {
      submitBtnState = false;
    }
    setBtnState(submitBtnState);
    setOTP(otp);
  };

  const verify = () => {
    let data = {
      otp_id: otpId,
      otp: otp,
    };
    verifyOTP(data)
      .then((res) => {
        if (type === 'mobile') {
          dispatch(setMobileVerified(true));
          if (localStorage.getItem('email') !== '') {
            navigate('/signin/password',{replace:true});
          } else {
            navigate('/signin/register',{replace:true});
          }
        } else {
          dispatch(setEmailVerified(true));
          if (localStorage.getItem('mobile') !== '') {
            navigate('/signin/password',{replace:true});
          } else {
            navigate('/signin/register',{replace:true});
          }
        }
      })
      .catch((err) => {
        if (err?.response.data.non_field_errors) {
          Toast.error(err?.response.data.non_field_errors[0]);
        } else {
          Toast.error(
            'Unable to verify OTP, please try again after some time.'
          );
        }
      });
  };

  const resendOtp = () => {
    resend(false);
  };

  const resendOtpViaCall = () => {
    resend(true);
  };

  const resend = (call) => {
    let req = {
      otp_id: otpId,
    };
    if (type === 'mobile') {
      req.mobile = mobile;
    } else {
      req.email = email;
    }
    if (call) {
      req.voice = 'true';
    }
    resendOTP(req)
      .then((res) => {
        setShowTimer(true);
      })
      .catch((err) => {
        if (err?.response.data.non_field_errors) {
          Toast.error(err?.response.data.non_field_errors[0]);
        } else {
          Toast.error(
            'Unable to resend OTP, please try again after some time.'
          );
        }
      });
  };

  return (
    <div className='row justify-content-center align-items-center h-100'>
      <BrowserView>
        <div className='p-5 colored-box'>
          <HeaderLogo />
          <div className='h4 font-weight-bold text-center'>Enter OTP</div>
          <p className='text-center mt-3'>
            {type === 'mobile'
              ? "We've sent you an SMS with six digit verification code on " +
                mobile
              : 'We have sent you an OTP to ' +
                email +
                '. If not received in inbox, please check your spam folder'}
          </p>
          <OTPInput
            autoFocus={true}
            isNumberInput
            length={6}
            className='mt-5 d-flex'
            inputClassName='form-control otp-input'
            onChangeOTP={otpChange}
          />
          <label
            className={
              'font-weight-medium ' + (setApiError ? 'is-invalid' : '')
            }
          >
            {apiError}
          </label>
          <div className='mt-5 d-flex justify-content-between align-items-center'>
            <div>
              <div className='d-flex justify-content-between align-items-center'>
                {showTimer ? (
                  <OTPTimer
                    className='enabled'
                    initialSeconds='30'
                    onComplete={setShowTimer}
                  ></OTPTimer>
                ) : (
                  ''
                )}
                <button
                  type='button'
                  className={
                    showTimer ? 'btn link-disabled' : 'btn link-enabled'
                  }
                  style={showTimer ? { pointerEvents: 'none' } : null}
                  onClick={resendOtp}
                >
                  Resend OTP
                </button>
              </div>
              <div>
                {type === 'mobile' ? (
                  <button
                    type='button'
                    className={
                      showTimer ? 'btn link-disabled' : 'btn link-enabled'
                    }
                    style={showTimer ? { pointerEvents: 'none' } : null}
                    onClick={resendOtpViaCall}
                  >
                    {' '}
                    Send OTP Via Call
                  </button>
                ) : null}
              </div>
            </div>
            <button
              type='button'
              className='btn btn-primary'
              onClick={verify}
              disabled={submitBtnState}
            >
              Next
            </button>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className='p-5 ml-4 mr-4 bg-light-grey'>
          <div className='h4 font-weight-bold text-center'>Enter OTP</div>
          <p className='text-center mt-3 opacity-75'>
            {type === 'mobile'
              ? "We've sent you an SMS with six digit verification code on " +
                mobile
              : 'We have sent you an OTP to ' +
                email +
                '. If not received in inbox, please check your spam folder'}
          </p>
          <OTPInput
            autoFocus={true}
            isNumberInput
            length={6}
            className='mt-5 d-flex  '
            inputClassName='updated-input bg-light-grey form-control otp-input'
            onChangeOTP={otpChange}
          />
          <label
            className={
              'font-weight-medium ' + (setApiError ? 'is-invalid' : '')
            }
          >
            {apiError}
          </label>
          <div>
            <div className='mt-2  justify-content-between align-items-center'>
              {showTimer ? (
                <OTPTimer
                  className='enabled'
                  initialSeconds='30'
                  onComplete={setShowTimer}
                ></OTPTimer>
              ) : (
                ''
              )}
              <span className='float-right mb-4'>
                <button
                  type='button'
                  className={
                    showTimer ? 'btn link-disabled ' : 'btn link-enabled '
                  }
                  style={showTimer ? { pointerEvents: 'none' } : null}
                  onClick={resendOtp}
                >
                  Resend OTP
                </button>
              </span>
            </div>
            <button
              type='button'
              className='btn btn-primary w-100'
              onClick={verify}
              disabled={submitBtnState}
            >
              Next
            </button>
            <div className='mt-2 justify-content-between align-items-center'>
              {type === 'mobile' ? (
                <button
                  type='button'
                  className={
                    showTimer ? 'btn link-disabled' : 'btn link-enabled'
                  }
                  style={showTimer ? { pointerEvents: 'none' } : null}
                  onClick={resendOtpViaCall}
                >
                  {' '}
                  Send OTP Via Call
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </MobileView>
    </div>
  );
};

OtpComponent.propTypes = {
  type: PropTypes.string,
};

export default OtpComponent;
