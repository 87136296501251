import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendOTP } from '../api/signup';
import Toast from '../components/toast/Toast';
import {
  setEmail,
  setMobile,
  setOtpId,
} from '../redux/reducers/signup-reducer';
import { emailRegex, mobileRegex } from '../utility/constant';
import HeaderLogo from './logo/HeaderLogo';
import PageBottom from './page-bottom/PageBottom';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [submitBtnState, setBtnState] = useState(true);
  const [error, setError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const submit = () => {
    if (!submitBtnState) {
      let validRegex = new RegExp(
        '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
      );
      let isEmail = validRegex.test(value);
      if (!process.env.REACT_APP_CLIENT_ID) {
        console.error('missing env REACT_APP_CLIENT_ID');
      }
      let req = {
        client_id: process.env.REACT_APP_CLIENT_ID,
      };
      if (isEmail) {
        req.email = value;
      } else {
        req.mobile = value;
      }
      sendOTP(req)
        .then((res) => {
          let otpId = res.data.otp_id;
          dispatch(setOtpId(otpId));
          localStorage.setItem('otpId', otpId);
          if (isEmail) {
            dispatch(setEmail(value));
            localStorage.setItem('email', value);
            navigate('/email/otp/verify');
          } else {
            dispatch(setMobile(value));
            localStorage.setItem('mobile', value);
            navigate('/mobile/otp/verify');
          }
        })
        .catch((err) => {
          if (err?.response.data.non_field_errors) {
            Toast.error(err?.response.data.non_field_errors[0]);
          } else {
            Toast.error('Something went wrong');
          }
        });
    }
  };

  const onValueChange = (event) => {
    let inputValue = event.target.value || '';
    let errorMsg = '',
      submitBtnState = true;
    if (!inputValue) {
      errorMsg = 'Please enter a valid Phone number or email address';
    } else if (isNaN(inputValue) && !emailRegex.test(inputValue)) {
      errorMsg = 'Please enter a valid email address';
      setPhoneNumber('');
    } else if (!isNaN(inputValue) && !mobileRegex.test(inputValue)) {
      errorMsg = 'Please enter a valid Phone number';
      setPhoneNumber(10);
    } else {
      submitBtnState = false;
      errorMsg = '';
    }
    if (isNaN(inputValue)) {
      inputValue = inputValue.toLowerCase();
    }
    setError(errorMsg);
    setBtnState(submitBtnState);
    setValue(inputValue);
  };

  const goToSingup = () => {
    navigate('/');
  };
  const enterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  };

  useEffect(() => {
    localStorage.setItem('mobile', '');
    localStorage.setItem('email', '');
  }, []);
  return (
    <div className='row justify-content-center align-items-center h-100'>
      <div className='col-sm-3'>
        <BrowserView>
          <div className=' p-5 colored-box mb-6'>
            <HeaderLogo />
            <div className='h4 text-center heading-custom'>Sign up</div>
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Label className='opacity-75 mb-3'>
                  Enter your email address or mobile number
                </Form.Label>
                <Form.Control
                  placeholder=''
                  maxLength={phoneNumber}
                  onChange={onValueChange}
                  value={value}
                  className={
                    'font-weight-medium ' + (error ? 'is-invalid' : '')
                  }
                  onKeyDown={enterHandler}
                />
                <Form.Control.Feedback type='invalid'>
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              <button
                type='button'
                className='btn link-enabled'
                onClick={goToSingup}
              >
                Login
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={submit}
                disabled={submitBtnState}
              >
                Next
              </button>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className=' p-5 bg-light-grey pb-6'>
            <div className='h4 text-center heading-custom'>Sign up</div>
            <Form.Group className='col-sm-6 px-0 my-3'>
              <Form.Label className=' opacity-50  mb-1'>
                Enter your email address or mobile number
              </Form.Label>
              <Form.Control
                type='text'
                className={
                  'updated-input pl-0 mt-2 font-weight-medium bg-light-grey'
                }
                onChange={onValueChange}
                value={value}
                onKeyDown={enterHandler}
                id='user_name'
                placeholder=''
                maxLength={phoneNumber}
              />
              <Form.Control.Feedback type='invalid'>
                {error}
              </Form.Control.Feedback>
            </Form.Group>
            <div className='mt-5 justify-content-between align-items-center'>
              <button
                type='button'
                className='btn btn-primary w-100'
                id='login_button'
                disabled={submitBtnState}
                onClick={submit}
              >
                Next
              </button>
              <p className='mobile-signup'>
                <span className='mt-2 pt-4 '>Dont have an account?</span>{' '}
                <button
                  type='button'
                  className='btn link-enabled'
                  onClick={goToSingup}
                >
                  {' '}
                  <span className='font-weight-medium ml-0'> Login </span>
                </button>
              </p>
            </div>
          </div>
        </MobileView>
        <PageBottom />
      </div>
    </div>
  );
};

export default SignIn;
