import { useAppSelector } from '../../redux/hooks';
import { getTenantName } from '../../utility/utility';
import tarrakki from './../../assets/elevo/tarrakki.svg';
import placeholderLogo from './../../assets/logo-placeholder.png';

const Logo = ({ classNames }: { classNames: string }) => {
  const { auth } = useAppSelector((state) => state.assestsReducers);

  // Use logo from assests if available or else if resolve from hostname
  let logo = auth?.logo;
  let powered_by = auth?.powered_by;

  // If not available from /config/assets then resolve it from locally
  if (!logo) {
    const tenantName = getTenantName();
    const tennatUrl = tenantName
      ? `${process.env.PUBLIC_URL}/${tenantName}/`
      : null;
    const svgUrl = tennatUrl + 'logo.svg';
    const pngUrl = tennatUrl + 'logo.png';
    const logoUrl = svgUrl ? svgUrl : pngUrl ? pngUrl : null;
    logo = logoUrl!;

    if (typeof powered_by !== 'boolean') {
      const tenantName = getTenantName();
      powered_by = tenantName === 'tarrakki' ? false : true;
    }
  }

  return (
    <>
      <div className={`${classNames} header_logo_wrapper`}>
        <img
          src={logo || placeholderLogo}
          className={'logo'}
          alt={'App Logo'}
        />
        {powered_by && (
          <>
            <div className='vertical_line'></div>
            <div className='powered_by_wrapper'>
              <p>powered by</p>
              <img
                src={tarrakki}
                className={'powered_by_logo'}
                alt='powered by tarrakki'
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Logo;
