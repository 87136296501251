import { Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedNav } from '../../redux/actions/headerAction';

import Logo from '../whitelabel/Logo';

import { Button } from 'react-bootstrap';

const Header = () => {
  const { activeLink, websiteURL } = useSelector(
    (state) => state.headerReducer
  );
  const config = useSelector((state) => state.headerReducer.appConfig);
  const blogUrl = config?.blog_url;
  const aboutUrl = config?.about_us_url;
  const dispatch = useDispatch();
  const updateActiveLink = (link) => {
    dispatch(updateSelectedNav(link));
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand='lg'
        variant='light'
        className='sticky-top navbar-light bg-white mt-2 mb-4 mx-4'
      >
        <a href={`${websiteURL}`} rel='noreferrer' className='navbar-brand'>
          <Logo classNames='ml-3' />
        </a>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse className='font-weight-medium'>
          <ul className='navbar-nav ml-auto mt-2'>
            <li
              className={`nav-item mr-4 ${
                activeLink === 'homepage' && 'active'
              }`}
              onClick={() => updateActiveLink('homepage')}
            >
              <a href={`${websiteURL}`} rel='noreferrer' className='nav-link'>
                Home
              </a>
            </li>
            {aboutUrl && (
              <li
                className={`nav-item mr-4 ${
                  activeLink === 'aboutus' && 'active'
                }`}
                onClick={() => updateActiveLink('aboutus')}
              >
                <a
                  href={aboutUrl}
                  target='_blank'
                  rel='noreferrer'
                  className='nav-link'
                >
                  About Us
                </a>
              </li>
            )}
            {blogUrl && (
              <li
                className={`nav-item mr-4 ${activeLink === 'blog' && 'active'}`}
                onClick={() => updateActiveLink('blog')}
              >
                <a
                  href={blogUrl}
                  target='_blank'
                  rel='noreferrer'
                  className='nav-link'
                >
                  Blog
                </a>
              </li>
            )}
            <li
              className={`nav-item  ${activeLink === 'login' && 'active'}`}
              onClick={() => updateActiveLink('login')}
            >
              <a href={'/'}>
                <Button
                  variant='outline-primary'
                  className='.btn-outline-primary'
                >
                  Login
                </Button>
              </a>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
