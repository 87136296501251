import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../api/authentication';
import { updateAuthData } from '../redux/actions/authAction';
import { useAppSelector } from '../redux/hooks';
import { decryptData, encryptData } from '../utility/DecryptHelper';
import { emailRegex, mobileRegex } from '../utility/constant';
import { getMFAppUrl } from '../utility/utility';
import { saveInfoWithKey } from './LoginSingupInfoManager';
import HeaderLogo from './logo/HeaderLogo';
import PageBottom from './page-bottom/PageBottom';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { appURLs } = useAppSelector((state) => state.headerReducer);

  const submit = () => {
    if (!userName) {
      setUserNameError('Please enter a valid Phone number or email address');
      return;
    }
    if (!password) {
      setPasswordError('Password can not be empty');
      return;
    }
    if (!userNameError && !passwordError) {
      const loginData = {
        username: userName,
        password: password,
      };
      let strLogin = JSON.stringify(loginData) + '*#$*';
      let encryptedData = encryptData(strLogin).toString();
      const data = new FormData();
      data.append('auth_data', encryptedData);

      login(data).then((res) => {
        if (res.data.data) {
          const resData = decryptData(res.data.data);
          if (res.data.status.code === 1) {
            saveInfoWithKey('userData', res.data.data, '/', true);
            saveInfoWithKey('flowData', null, '/', false);
            dispatch(updateAuthData(resData));
            const newData = window.btoa(
              unescape(encodeURIComponent(JSON.stringify(resData)))
            );

            // Check if search params to redirect path is specified by the referrer or not
            const searchParams = new URLSearchParams(window.location.search);
            const redirect_to = searchParams.get('redirect_to');

            window.location = getMFAppUrl(
              newData,
              appURLs['mf'],
              redirect_to ? redirect_to : ''
            );
          }
        }
      });
    }
  };

  const userNameHandler = (event) => {
    let inputValue = event.target.value || '',
      phoneErr = '';
    if (!inputValue) {
      phoneErr = 'Please enter a valid Phone number or email address';
    } else if (isNaN(inputValue) && !emailRegex.test(inputValue)) {
      phoneErr = 'Please enter a valid email address';
      setPhoneNumber('');
    } else if (!isNaN(inputValue) && !mobileRegex.test(inputValue)) {
      phoneErr = 'Please enter a valid Phone number';
      setPhoneNumber(10);
    } else {
      phoneErr = '';
    }
    if (isNaN(inputValue)) {
      inputValue = inputValue.toLowerCase();
    }
    setUserNameError(phoneErr);
    setUserName(inputValue);
  };

  const passwordChange = (event) => {
    let password = event.target.value || '',
      passwordErr = '';
    if (!password) {
      passwordErr = 'Password can not be empty';
    }
    setPasswordError(passwordErr);
    setPassword(password);
  };

  const goToSingup = () => {
    navigate('/signup');
  };

  const enterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  };
  return (
    <div className='row justify-content-center align-items-center h-100'>
      <div className='col-sm-3 px-0'>
        <BrowserView>
          <div className=' p-5 colored-box mb-6'>
            <HeaderLogo />
            <div className='h4 text-center heading-custom'>Log In</div>
            <div className='row mt-5 d-flex justify-content-between align-items-center'>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Control
                  placeholder='Enter Email Id or Mobile no.'
                  maxLength={phoneNumber}
                  onChange={userNameHandler}
                  value={userName}
                  onKeyDown={enterHandler}
                  id='user_name'
                  className={
                    'font-weight-medium ' + (userNameError ? 'is-invalid' : '')
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {userNameError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='col-sm-12  px-0'>
                <Form.Control
                  type='password'
                  placeholder='Password'
                  onChange={passwordChange}
                  onKeyDown={enterHandler}
                  value={password}
                  id='user_password'
                  className={
                    'font-weight-medium ' + (passwordError ? 'is-invalid' : '')
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {passwordError}
                </Form.Control.Feedback>
              </Form.Group>
              <div>
                <Link to='emailormobileverify'>
                  <p>Forgot Password?</p>
                </Link>
              </div>
            </div>
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              <button
                type='button'
                className='btn link-enabled'
                onClick={goToSingup}
              >
                {' '}
                Sign Up
              </button>
              <button
                type='button'
                className='btn btn-primary'
                id='login_button'
                onClick={submit}
              >
                Next
              </button>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <>
            <div className='h4 text-center mobile-heading-custom heading-custom mb-0 pt-4'>
              Login
            </div>
            <div className='mobile-login'>
              <Form.Group className='col-sm-6 px-0 my-3'>
                <Form.Label className=' opacity-50  mb-1'>
                  Email ID / Mobile Number
                </Form.Label>
                <Form.Control
                  type='text'
                  className={
                    'updated-input pl-0 font-weight-medium bg-light-grey'
                  }
                  onChange={userNameHandler}
                  value={userName}
                  onKeyDown={enterHandler}
                  id='user_name'
                />
                <Form.Control.Feedback type='invalid'>
                  {userNameError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='col-sm-6 px-0 my-3'>
                <Form.Label className=' opacity-50  mb-1'>Password</Form.Label>
                <Form.Control
                  type='password'
                  className={
                    'updated-input pl-0 font-weight-medium bg-light-grey'
                  }
                  onChange={passwordChange}
                  onKeyDown={enterHandler}
                  value={password}
                  id='user_password'
                />
                <Form.Control.Feedback type='invalid'>
                  {passwordError}
                </Form.Control.Feedback>
              </Form.Group>
              <div style={{ float: 'right' }}>
                <Link to='emailormobileverify'>
                  <p>Forgot Password?</p>
                </Link>
              </div>
              <button
                type='button'
                className='btn btn-primary w-100'
                id='login_button'
                onClick={submit}
              >
                Login
              </button>
              <p className='mobile-signup'>
                <span className='mt-4 pt-4'>Dont have an account?</span>{' '}
                <button
                  type='button'
                  className='btn link-enabled'
                  onClick={goToSingup}
                >
                  {' '}
                  <span className='font-weight-medium ml-0'>Sign Up</span>
                </button>
              </p>
            </div>
          </>
        </MobileView>
        <PageBottom />
      </div>
    </div>
  );
};

export default Login;
