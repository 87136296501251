import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import successfulIcon from '../assets/successful.svg';
import { Link } from 'react-router-dom';
const AddFundTimeModal = ({ show }) => {
  return (
    <Modal
      show={show}
      size='sm'
      aria-labelledby='confirmation-modal'
      className='mr-4 ml-0'
      centered
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body className='text-center'>
        <div className='img-container mb-4'>
          <img src={successfulIcon} alt='successful icon' className='logo' />
        </div>
        <p className='font-weight-medium'>
          {'Password has been updated successfully. Please login'}
        </p>
        <Link to='/'>
          <button type='button' className='btn btn-primary '>
            Close
          </button>
        </Link>
      </Modal.Body>
    </Modal>
  );
};
AddFundTimeModal.propTypes = {
  message: PropTypes.string,
  header: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  fundName: PropTypes.string,
};
export default AddFundTimeModal;
