import React from 'react';
import PageBottom from '../components/page-bottom/PageBottom';
import { Link } from 'react-router-dom';
import { getInfo } from './LoginSingupInfoManager';
import HeaderLogo from './logo/HeaderLogo';

const getUserInfo = () => {
  const data = getInfo();
  let output = data.data;
  return output;
};

const ResetSuccess = () => {
  return (
    <div className='row justify-content-center align-items-center h-100'>
      <div className='col-sm-3'>
        <div className=' p-5 colored-box mb-6'>
          <HeaderLogo />
          <div className='h4 text-center heading-custom'>{getUserInfo()}</div>
          <div className='row mt-5 d-flex justify-content-center align-items-center'>
            <Link to='/'>
              <button type='button' className='btn btn-primary '>
                Ok
              </button>
            </Link>
          </div>
        </div>
        <PageBottom />
      </div>
    </div>
  );
};

export default ResetSuccess;
