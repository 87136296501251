import axios from './axios';


const sendOTP = (postData) => {
  return axios.post('mfapi/signup/otp/', postData);
};

const resendOTP = (postData) => {
  return axios.post('mfapi/signup/otp/resend/', postData);
};

const verifyOTP = (postData) => {
  return axios.post('mfapi/signup/otp/verify/', postData);
}

const signup = (postData) => {
  return axios.post('mfapi/signup/', postData);
}

export { sendOTP, resendOTP, verifyOTP, signup }