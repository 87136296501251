import React from 'react';
import Logo from '../whitelabel/Logo';

const HeaderLogo = () => {
  return (
    <div className='logo-container mb-5 text-center'>
      <Logo />
    </div>
  );
};

export default HeaderLogo;
