import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  email: string | null;
  is_email_activated: boolean;
  is_kyc_verified: boolean;
  is_mobile_verified: boolean;
  mobile_number: string | null;
  user_id: string | null;
  rise: {
    tax_status: Record<string, any>;
    user_profile_picture: string;
  };
}

const initialState: AuthState = {
  email: null,
  is_email_activated: false,
  is_kyc_verified: false,
  is_mobile_verified: false,
  mobile_number: null,
  user_id: null,
  rise: {
    tax_status: {},
    user_profile_picture: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginData: (state, action: PayloadAction<Partial<AuthState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateProfile: (state, action: PayloadAction<AuthState['rise']>) => {
      state.rise = action.payload;
    },
    removeLoginData: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { setLoginData, updateProfile, removeLoginData } = authSlice.actions;
export default authSlice.reducer;
