import { createSlice } from '@reduxjs/toolkit';

interface AssestsInterface {
  title: string;
  id: string;
  powered_by: boolean;
  favicon: string;
  logo: string;
}

interface InitiInterface {
  auth: AssestsInterface | null;
}

const initialState: InitiInterface = {
  auth: null,
};

const assestsSlice = createSlice({
  name: 'assests',
  initialState,
  reducers: {
    setAssests: (state, action) => {
      state.auth = action.payload;
    },
  },
});

export const { setAssests } = assestsSlice.actions;

export default assestsSlice.reducer;
