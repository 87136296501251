import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTenantName } from '../../utility/utility';

interface HeaderState {
  showCart: boolean;
  showPages: boolean;
  showProfile: boolean;
  showIcon: boolean;
  showProfileIcon: boolean;
  activeLink: string;
  appConfig: Record<string, any>;
  tenantName: string;
  appURLs: AppURLs;
  websiteURL: string | null;
}

interface AppURLs {
  [key: string]: string;
}

const initialState: HeaderState = {
  showCart: true,
  showPages: true,
  showProfile: true,
  showIcon: true,
  showProfileIcon: true,
  activeLink: 'home',
  appConfig: {},
  tenantName: getTenantName(),
  appURLs: {},
  websiteURL: null,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    showHeaderOptions: (state, action: PayloadAction<Partial<HeaderState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    navSelectionChange: (state, action: PayloadAction<string>) => {
      state.activeLink = action.payload;
    },
    setAppConfig: (state, action: PayloadAction<Record<string, any>>) => {
      state.appConfig = action.payload;
    },
    setAppURLs: (state, action) => {
      action.payload?.apps?.forEach((item: any) => {
        state.appURLs[item.app] = item.url;
      });
    },
    setWebsiteURL: (state, action) => {
      action.payload?.apps?.forEach((ele: { [key: string]: string }) => {
        if (ele?.app === 'website') {
          state.websiteURL = ele?.url;
        }
      });
    },
  },
});

export const {
  showHeaderOptions,
  navSelectionChange,
  setAppConfig,
  setAppURLs,
  setWebsiteURL,
} = headerSlice.actions;
export default headerSlice.reducer;
