

function imageExists(image_url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = image_url + "?" + Date.now();
    
  });
}

export {imageExists};