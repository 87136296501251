import React, { useState, useEffect } from 'react';
import { imageExists } from '../../utility/whiteLable';
import { useSelector } from 'react-redux';

const PageBottom = () => {
  const config = useSelector((state) => state.headerReducer.appConfig);
  const tenant = config?.tenant;
  const tennatUrl = tenant ? `${process.env.PUBLIC_URL}/${tenant}/` : null;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [bottomIcon, setBottomIcon] = useState([]);
  const BOTTOM_ICON_TEXT = 'Your data is secure with AES-256 encryption';

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const imagePath = [
    tennatUrl + 'bottomIcons/AMFI.svg',
    tennatUrl + 'bottomIcons/BSE.svg',
    tennatUrl + 'bottomIcons/SEBI.svg',
  ];

  useEffect(() => {
    async function loadIcons() {
      const promises = imagePath.map((item) => imageExists(item));
      const results = await Promise.all(promises);

      const bottomIcons = imagePath.filter((_, index) => results[index]);
      setBottomIcon(bottomIcons);
    }

    loadIcons();
    // eslint-disable-next-line
  }, [tenant]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      {bottomIcon.length > 0 && (
        <div className='colored-box mb-4'>
          <div
            className={`pt-4 d-flex align-items-center text-center justify-content-around ${
              windowDimensions.width > 480 ? null : 'bg-light-grey'
            }`}
          >
            {bottomIcon?.map((item, index) => (
              <div key={`enc-${index}`} className='img-container'>
                {<img src={item} alt='icon-1' className='w-75' />}
              </div>
            ))}
          </div>
          <div
            className={`py-3 text-center ${
              windowDimensions.width > 480 ? null : 'bg-light-grey'
            }`}
          >
            <span className='opacity-50'>{BOTTOM_ICON_TEXT}</span>
          </div>
        </div>
      )}
    </>
  );
};
export default PageBottom;
