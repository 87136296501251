import PropTypes from 'prop-types';
import { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordOTP, getOTP } from '../api/authentication';
import OTPTimer from '../components/Timer';
import PageBottom from '../components/page-bottom/PageBottom';
import { decryptData } from '../utility/DecryptHelper';
import { getInfo, saveInfo, saveInfoAndPush } from './LoginSingupInfoManager';
import OTPInput from './OTPInputComponent';
import HeaderLogo from './logo/HeaderLogo';

const OtpVerify = ({ type }) => {
  const [otp, setOTP] = useState('');
  const [submitBtnState, setBtnState] = useState(false);
  const [apiError, setApiError] = useState('');
  const [showTimer, setShowTimer] = useState(true);
  const navigate = useNavigate();

  // const getUserInfo = () => {
  //   const data = getInfo();
  //   const jsonData = data.data;
  //   let userInput = jsonData.userId;
  //   let visibleInput = userInput.slice(0, 4);
  //   let nonVisibleInput = userInput.slice(4, userInput.length);
  //   let replace = nonVisibleInput.replace(/./gi, 'x');
  //   let userData = visibleInput + replace;
  //   return userData;
  // };
  const getUserMobile = () => {
    let visbleInput = '+91';
    let nonVisibleInput = 'XXXXXXXXXX';
    let userData = visbleInput + nonVisibleInput;
    return userData;
  };

  const otpChange = (event) => {
    let otp = event || '',
      submitBtnState = false;
    const pattern = /^([0-9]{0,6})?$/;

    if (!otp) {
      submitBtnState = false;
    } else if (!pattern.test(otp)) {
      submitBtnState = true;
    } else {
      submitBtnState = false;
    }
    setBtnState(submitBtnState);
    setOTP(otp);
  };

  const redirect = (data) => {
    const loginData = decryptData(data);
    saveInfoAndPush(loginData, '/resetpassword', false, navigate);
  };

  const verify = () => {
    const edata = getInfo();
    const jsonData = edata.data;
    const data = new FormData();
    data.append('otp_id', jsonData.otp_id);
    data.append('otp', otp);
    getOTP(data).then((res) => {
      if (res.data.status.code === 1) {
        redirect(res.data.data);
      } else {
        setApiError(res.data.status.message);
        setOTP('');
      }
    });
  };

  const resendOtp = () => {
    resend(false);
  };

  const resendOtpViaCall = () => {
    resend(true);
  };

  const resend = (call) => {
    const userData = getInfo();
    const jsonData = userData.data;
    const data = new FormData();
    data.append('email', jsonData.userId);
    if (call) {
      data.append('voice', true);
    }
    forgotPasswordOTP(data).then((res) => {
      if (res.data.status.code === 1) {
        const otpData = decryptData(res.data.data);
        otpData['userId'] = jsonData.userId;
        saveInfo(otpData, '', false);
        setShowTimer(true);
      }
    });
  };

  return (
    <div className='row justify-content-center align-items-center h-100'>
      <div className='col-sm-3 px-0'>
        <BrowserView>
          <div className=' p-5 colored-box mb-6'>
            <HeaderLogo />
            <div className='h4 text-center heading-custom'>
              OTP Verification
            </div>
            <p className='text-center mt-3'>
              {'OTP has been sent to:- ' + getUserMobile()}
            </p>
            <div className='row mt-5 d-flex justify-content-center align-items-center'>
              <OTPInput
                autoFocus={true}
                isNumberInput
                length={6}
                className='mt-5 d-flex  '
                inputClassName='updated-input  form-control otp-input'
                onChangeOTP={otpChange}
              />
            </div>
            <label
              className={
                'font-weight-medium ' + (setApiError ? 'is-invalid' : '')
              }
            >
              {apiError}
            </label>
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              <div>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>Didnt recieved the OTP?</span>
                  <button
                    type='button'
                    className={
                      showTimer ? 'btn link-disabled' : 'btn link-enabled'
                    }
                    style={showTimer ? { pointerEvents: 'none' } : null}
                    onClick={resendOtp}
                  >
                    Resend OTP
                  </button>
                  {showTimer ? (
                    <OTPTimer
                      className='enabled'
                      initialSeconds='30'
                      onComplete={setShowTimer}
                    ></OTPTimer>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {type === 'mobile' ? (
                    <button
                      type='button'
                      className={
                        showTimer ? 'btn link-disabled' : 'btn link-enabled'
                      }
                      style={showTimer ? { pointerEvents: 'none' } : null}
                      onClick={resendOtpViaCall}
                    >
                      {' '}
                      Send OTP Via Call
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row justify-content-center align-items-center mt-4'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={verify}
                disabled={submitBtnState}
              >
                Continue
              </button>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className=' p-5 bg-light-grey pb-6'>
            <div className='h4 text-center heading-custom'>
              OTP Verification
            </div>
            <p className='text-center mt-3'>
              {'OTP has been sent to:- ' + getUserMobile()}
            </p>
            <div className='row mt-5 d-flex justify-content-center align-items-center'>
              <OTPInput
                autoFocus={true}
                isNumberInput
                length={6}
                className='mt-5 d-flex  '
                inputClassName='updated-input bg-light-grey form-control otp-input'
                onChangeOTP={otpChange}
              />
            </div>
            <label
              className={
                'font-weight-medium ' + (setApiError ? 'is-invalid' : '')
              }
            >
              {apiError}
            </label>
            <div className='row justify-content-center align-items-center mt-4'>
              <button
                type='button'
                className='btn btn-primary w-100'
                onClick={verify}
                disabled={submitBtnState}
              >
                Continue
              </button>
            </div>
            {showTimer ? (
              <OTPTimer
                className='enabled'
                initialSeconds='30'
                onComplete={setShowTimer}
              ></OTPTimer>
            ) : (
              ''
            )}
            <div className='mt-5 d-flex justify-content-between align-items-center'>
              <div>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>Didnt recieved the OTP?</span>
                  <button
                    type='button'
                    className={
                      showTimer ? 'btn link-disabled' : 'btn link-enabled'
                    }
                    style={showTimer ? { pointerEvents: 'none' } : null}
                    onClick={resendOtp}
                  >
                    Resend OTP
                  </button>
                </div>
                <div>
                  {type === 'mobile' ? (
                    <button
                      type='button'
                      className={
                        showTimer ? 'btn link-disabled' : 'btn link-enabled'
                      }
                      style={showTimer ? { pointerEvents: 'none' } : null}
                      onClick={resendOtpViaCall}
                    >
                      {' '}
                      Send OTP Via Call
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </MobileView>
        <PageBottom />
      </div>
    </div>
  );
};
OtpVerify.propTypes = {
  type: PropTypes.string,
};
export default OtpVerify;
