/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

const OTPTimer = ({ className, onComplete, initialSeconds = 0 }) => {
  const getMinutes = (seconds) => {
    const minutes = (seconds - (seconds % 60)) / 60;
    return minutes;
  };
  const getSeconds = (seconds) => {
    return seconds % 60;
  };
  const [seconds, setSeconds] = useState(getSeconds(initialSeconds));
  const [minutes, setMinutes] = useState(getMinutes(initialSeconds));

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          onComplete();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <text className={className}>
      {minutes === 0 && seconds === 0
        ? ''
        : (minutes < 10 ? `0${minutes}` : minutes) +
          ':' +
          (seconds < 10 ? `0${seconds}` : seconds)}
    </text>
  );
};

export default OTPTimer;
