import assestsReducers from './assests-reducers';
import authReducer from './auth-reducer';
import headerReducer from './header-reducer';
import signUpReducer from './signup-reducer';

const rootReducer = {
  authReducer,
  headerReducer,
  signUpReducer,
  assestsReducers,
};

export default rootReducer;
