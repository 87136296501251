import { useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import { getFreshChatStatus } from './api/app_config';
import { getWhitelabelDetails } from './api/whitelabel.service';
import Header from './components/header/Header';
import HeaderMobile from './components/header/HeaderMobile';
import { updateAppConfig } from './redux/actions/headerAction';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { setAssests } from './redux/reducers/assests-reducers';
import { setAppURLs, setWebsiteURL } from './redux/reducers/header-reducer';
import Routers from './routers/Routers';
import { getTenantName } from './utility/utility';

const App = () => {
  // TODO: get whitelabel config here and set from here only
  const dispatch = useDispatch();
  const { auth } = useAppSelector((state) => state.assestsReducers);
  const dispatchHook = useAppDispatch();

  useEffect(() => {
    getWhitelabelDetails()
      .then((res: any) => {
        dispatch(setAssests(res));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    let tenantName = getTenantName();
    if (tenantName == 'localhost') {
      tenantName = 'elevo';
    }
    document.title = auth?.title ?? tenantName.toUpperCase() + ' | Auth';
    const faviconUrl =
      auth?.favicon ??
      window.location.origin + '/' + tenantName + '/favicon.ico';
    document
      .getElementById('dynamic-app-favicon')!
      .setAttribute('href', faviconUrl);
  }, [auth]);

  useEffect(() => {
    getFreshChatStatus().then((res) => {
      dispatch(updateAppConfig(res.data));
      dispatchHook(setAppURLs(res.data));
      dispatchHook(setWebsiteURL(res.data));
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <BrowserView>
        <div className='container-fluid'>
          <Header />
          <Routers />
        </div>
      </BrowserView>
      <MobileView>
        <>
          <HeaderMobile />
          <Routers />
        </>
      </MobileView>
    </Router>
  );
};

export default App;
