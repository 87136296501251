import axios from './axios';

const forgotPasswordOTP = (postData) => {
  if (process.env?.NODE_ENV === 'development') {
  }
  return axios.post('/users/forgot-password/', postData);
};

const getOTP = (postData) => {
  return axios.post('/users/get_otp/', postData);
};

const resetPassword = (postData) => {
  return axios.post('/users/forgot-password/confirm/', postData);
};

const verifyOTP = (postData) => {
  return axios.post('users/verify-otp/', postData);
};

const login = (postData) => {
  return axios.post('/api-token-auth/', postData);
};
export { forgotPasswordOTP, getOTP, login, resetPassword, verifyOTP };

