import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
}

interface SignUpState {
  otpId: string;
  emailVerified: boolean;
  mobileVerified: boolean;
  user: UserState;
}

const initialUserState: UserState = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
};

const initialState: SignUpState = {
  otpId: '',
  emailVerified: false,
  mobileVerified: false,
  user: initialUserState,
};

const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setOtpId: (state, action: PayloadAction<string>) => {
      state.otpId = action.payload;
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.user.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.user.lastName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.user.email = action.payload;
    },
    setMobile: (state, action: PayloadAction<string>) => {
      state.user.mobile = action.payload;
    },
    setEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.emailVerified = action.payload;
    },
    setMobileVerified: (state, action: PayloadAction<boolean>) => {
      state.mobileVerified = action.payload;
    },
  },
});

export const { setOtpId, setFirstName, setLastName, setEmail, setMobile, setEmailVerified, setMobileVerified } = signUpSlice.actions;
export default signUpSlice.reducer;
